export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isMobileOrTablet, isDesktop } = useDevice();

  if (process.env.NODE_ENV === 'local') {
    return;
  }

  if (isMobileOrTablet && to.path === '/desktop') {
    return await navigateTo('/');
  }

  if (isDesktop && to.path !== '/desktop') {
    return await navigateTo('/desktop');
  }
});
