import type { IStore } from '~/interfaces/Store';

export const useStore = () => {
  const loading = ref(false);
  const client = useSanctumClient();
  const store = useState<IStore>('store', () => ({} as IStore));

  const loadStore = async (slug: string) => {
    loading.value = true;
    try {
      const { data } = await useAsyncData<IStore>(() =>
        client(`/api/stores/${slug}`)
      );

      store.value = data.value as IStore;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    loadStore,
    store,
  };
};
