export default defineAppConfig({
  ui: {
    primary: 'orange',
    accent: 'lima',
    button: {
      default: {
        color: 'orange',
      },
      variant: {
        plain: 'text-white items-center p-0',
      },
    },
    slideover: {
      base: 'rounded-t-3xl',
    },
    skeleton: {
      background: 'bg-gray-200'
    },
    divider: {
      wrapper: { base: 'flex items-center align-center text-center my-1' },
      border: {
        base: 'flex border-body_secondary-500',
      },
    },
  },
  nuxtIcon: {
    size: '2rem',
    mode: 'svg',
  },
});
