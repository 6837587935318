import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()

    const reverbConfig = config.public.reverb as { appKey: string, host: string, port: string, scheme?: string }

    
    window.Echo = new Echo({
        Pusher: Pusher,
        broadcaster: 'reverb',
        key:  reverbConfig.appKey,
        wsHost: reverbConfig.host,        
        wsPort: reverbConfig.port ?? 80,
        wssPort: 443,
        forceTLS: reverbConfig.scheme === 'https',
        enabledTransports: ['wss','ws'],       
    })    

    // inject('echo', echo)
})

