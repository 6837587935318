import revive_payload_client_4sVQNw7RlN from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/.nuxt/sentry-client-config.mjs";
import plugin_t2GMTTFnMT from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_vfCt6Q18Tc from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import pwa_icons_Y6agwlnXZq from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import slideovers_LDumGYo2KH from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import echo_client_DTUzXmgq1G from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/echo.client.ts";
import countdown_olk6LFpgGt from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/countdown.ts";
import date_LyjcPwWLrp from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/date.ts";
import formatData_NhY6TO3LDn from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/formatData.ts";
import maska_UHaKf2z1iQ from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/maska.ts";
import validateCPF_14CEQo3eKD from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/plugins/validateCPF.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_t2GMTTFnMT,
  plugin_WLsn00x1qh,
  plugin_vfCt6Q18Tc,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  echo_client_DTUzXmgq1G,
  countdown_olk6LFpgGt,
  date_LyjcPwWLrp,
  formatData_NhY6TO3LDn,
  maska_UHaKf2z1iQ,
  validateCPF_14CEQo3eKD
]