import validate from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45desktop_45global from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/middleware/redirectDesktop.global.ts";
import manifest_45route_45rule from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45desktop_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "verify-user-address": () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/middleware/verifyUserAddress.ts"),
  "verify-your-email": () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/middleware/verifyYourEmail.ts"),
  "sanctum:auth": () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}