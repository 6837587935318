export const appHead = {"meta":[{"name":"viewport","content":"minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no"},{"charset":"utf-8"},{"name":"description","content":"Conheça centenas de board games na sua biblioteca de jogos de tabuleiro modernos em Porto Alegre. Indicamos os jogos certos para alugar ou jogar no local."},{"property":"og:locale","content":"pt_PT"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Lends Club"},{"property":"og:url","content":"https://lends.com.br/"}],"link":[],"style":[],"script":[],"noscript":[{"children":"<img height=\"1\" width=\"1\" style=\"display:none\" src=\"https://www.facebook.com/tr?id=873354776463338&ev=PageView&noscript=1"}],"viewport":"minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no","title":"Lends Club"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false