import { default as loginzTDmCfN2J6Meta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/login.vue?macro=true";
import { default as registernaqMjRiW2uMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/register.vue?macro=true";
import { default as reset_45passwordbsvt2PsRMlMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/reset-password.vue?macro=true";
import { default as signupNvFrKlOUbwMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/signup.vue?macro=true";
import { default as _91id_93rfD98DV3ZJMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/boardgame/[id].vue?macro=true";
import { default as indexgSkNeVgQ7KMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/cart/index.vue?macro=true";
import { default as _91id_93xPzeUqZqmoMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/[id].vue?macro=true";
import { default as add_45new_45carddjkyl05BQuMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/add-new-card.vue?macro=true";
import { default as indexatm9V9kIhDMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/index.vue?macro=true";
import { default as _91id_93JLNwag1vPyMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/pix/[id].vue?macro=true";
import { default as index5P83ndldxZMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/collect/index.vue?macro=true";
import { default as indexPkoK4a6TAzMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/desktop/index.vue?macro=true";
import { default as indexB2RlVQTxObMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/index.vue?macro=true";
import { default as _91id_93iyQj5dwQK0Meta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/[id].vue?macro=true";
import { default as create8wKyKzRZ4hMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/create.vue?macro=true";
import { default as indexBG03UgdO5HMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/index.vue?macro=true";
import { default as _91id_93VR7KAOR9nEMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/order/[id].vue?macro=true";
import { default as indexFuQkdNKtqwMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/order/index.vue?macro=true";
import { default as add_45addressIDFnTIgt4PMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/add-address.vue?macro=true";
import { default as form_45personal_45datajdaOToDu6LMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/form-personal-data.vue?macro=true";
import { default as indexWBV1cI1zWdMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/index.vue?macro=true";
import { default as indexAJokLrfIYZMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/payment/index.vue?macro=true";
import { default as settingsbsBr5Y9upcMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/settings.vue?macro=true";
import { default as indexZkbTLpMpLHMeta } from "/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/search/index.vue?macro=true";
export default [
  {
    name: loginzTDmCfN2J6Meta?.name ?? "auth-login",
    path: loginzTDmCfN2J6Meta?.path ?? "/auth/login",
    meta: loginzTDmCfN2J6Meta || {},
    alias: loginzTDmCfN2J6Meta?.alias || [],
    redirect: loginzTDmCfN2J6Meta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registernaqMjRiW2uMeta?.name ?? "auth-register",
    path: registernaqMjRiW2uMeta?.path ?? "/auth/register",
    meta: registernaqMjRiW2uMeta || {},
    alias: registernaqMjRiW2uMeta?.alias || [],
    redirect: registernaqMjRiW2uMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordbsvt2PsRMlMeta?.name ?? "auth-reset-password",
    path: reset_45passwordbsvt2PsRMlMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordbsvt2PsRMlMeta || {},
    alias: reset_45passwordbsvt2PsRMlMeta?.alias || [],
    redirect: reset_45passwordbsvt2PsRMlMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signupNvFrKlOUbwMeta?.name ?? "auth-signup",
    path: signupNvFrKlOUbwMeta?.path ?? "/auth/signup",
    meta: signupNvFrKlOUbwMeta || {},
    alias: signupNvFrKlOUbwMeta?.alias || [],
    redirect: signupNvFrKlOUbwMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rfD98DV3ZJMeta?.name ?? "boardgame-id",
    path: _91id_93rfD98DV3ZJMeta?.path ?? "/boardgame/:id()",
    meta: _91id_93rfD98DV3ZJMeta || {},
    alias: _91id_93rfD98DV3ZJMeta?.alias || [],
    redirect: _91id_93rfD98DV3ZJMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/boardgame/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgSkNeVgQ7KMeta?.name ?? "cart",
    path: indexgSkNeVgQ7KMeta?.path ?? "/cart",
    meta: indexgSkNeVgQ7KMeta || {},
    alias: indexgSkNeVgQ7KMeta?.alias || [],
    redirect: indexgSkNeVgQ7KMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xPzeUqZqmoMeta?.name ?? "checkout-id",
    path: _91id_93xPzeUqZqmoMeta?.path ?? "/checkout/:id()",
    meta: _91id_93xPzeUqZqmoMeta || {},
    alias: _91id_93xPzeUqZqmoMeta?.alias || [],
    redirect: _91id_93xPzeUqZqmoMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45new_45carddjkyl05BQuMeta?.name ?? "checkout-add-new-card",
    path: add_45new_45carddjkyl05BQuMeta?.path ?? "/checkout/add-new-card",
    meta: add_45new_45carddjkyl05BQuMeta || {},
    alias: add_45new_45carddjkyl05BQuMeta?.alias || [],
    redirect: add_45new_45carddjkyl05BQuMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/add-new-card.vue").then(m => m.default || m)
  },
  {
    name: indexatm9V9kIhDMeta?.name ?? "checkout",
    path: indexatm9V9kIhDMeta?.path ?? "/checkout",
    meta: indexatm9V9kIhDMeta || {},
    alias: indexatm9V9kIhDMeta?.alias || [],
    redirect: indexatm9V9kIhDMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JLNwag1vPyMeta?.name ?? "checkout-pix-id",
    path: _91id_93JLNwag1vPyMeta?.path ?? "/checkout/pix/:id()",
    meta: _91id_93JLNwag1vPyMeta || {},
    alias: _91id_93JLNwag1vPyMeta?.alias || [],
    redirect: _91id_93JLNwag1vPyMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/checkout/pix/[id].vue").then(m => m.default || m)
  },
  {
    name: index5P83ndldxZMeta?.name ?? "collect",
    path: index5P83ndldxZMeta?.path ?? "/collect",
    meta: index5P83ndldxZMeta || {},
    alias: index5P83ndldxZMeta?.alias || [],
    redirect: index5P83ndldxZMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/collect/index.vue").then(m => m.default || m)
  },
  {
    name: indexPkoK4a6TAzMeta?.name ?? "desktop",
    path: indexPkoK4a6TAzMeta?.path ?? "/desktop",
    meta: indexPkoK4a6TAzMeta || {},
    alias: indexPkoK4a6TAzMeta?.alias || [],
    redirect: indexPkoK4a6TAzMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/desktop/index.vue").then(m => m.default || m)
  },
  {
    name: indexB2RlVQTxObMeta?.name ?? "index",
    path: indexB2RlVQTxObMeta?.path ?? "/",
    meta: indexB2RlVQTxObMeta || {},
    alias: indexB2RlVQTxObMeta?.alias || [],
    redirect: indexB2RlVQTxObMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iyQj5dwQK0Meta?.name ?? "list-id",
    path: _91id_93iyQj5dwQK0Meta?.path ?? "/list/:id()",
    meta: _91id_93iyQj5dwQK0Meta || {},
    alias: _91id_93iyQj5dwQK0Meta?.alias || [],
    redirect: _91id_93iyQj5dwQK0Meta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/[id].vue").then(m => m.default || m)
  },
  {
    name: create8wKyKzRZ4hMeta?.name ?? "list-create",
    path: create8wKyKzRZ4hMeta?.path ?? "/list/create",
    meta: create8wKyKzRZ4hMeta || {},
    alias: create8wKyKzRZ4hMeta?.alias || [],
    redirect: create8wKyKzRZ4hMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/create.vue").then(m => m.default || m)
  },
  {
    name: indexBG03UgdO5HMeta?.name ?? "list",
    path: indexBG03UgdO5HMeta?.path ?? "/list",
    meta: indexBG03UgdO5HMeta || {},
    alias: indexBG03UgdO5HMeta?.alias || [],
    redirect: indexBG03UgdO5HMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/list/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VR7KAOR9nEMeta?.name ?? "order-id",
    path: _91id_93VR7KAOR9nEMeta?.path ?? "/order/:id()",
    meta: _91id_93VR7KAOR9nEMeta || {},
    alias: _91id_93VR7KAOR9nEMeta?.alias || [],
    redirect: _91id_93VR7KAOR9nEMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFuQkdNKtqwMeta?.name ?? "order",
    path: indexFuQkdNKtqwMeta?.path ?? "/order",
    meta: indexFuQkdNKtqwMeta || {},
    alias: indexFuQkdNKtqwMeta?.alias || [],
    redirect: indexFuQkdNKtqwMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: add_45addressIDFnTIgt4PMeta?.name ?? "profile-add-address",
    path: add_45addressIDFnTIgt4PMeta?.path ?? "/profile/add-address",
    meta: add_45addressIDFnTIgt4PMeta || {},
    alias: add_45addressIDFnTIgt4PMeta?.alias || [],
    redirect: add_45addressIDFnTIgt4PMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/add-address.vue").then(m => m.default || m)
  },
  {
    name: form_45personal_45datajdaOToDu6LMeta?.name ?? "profile-form-personal-data",
    path: form_45personal_45datajdaOToDu6LMeta?.path ?? "/profile/form-personal-data",
    meta: form_45personal_45datajdaOToDu6LMeta || {},
    alias: form_45personal_45datajdaOToDu6LMeta?.alias || [],
    redirect: form_45personal_45datajdaOToDu6LMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/form-personal-data.vue").then(m => m.default || m)
  },
  {
    name: indexWBV1cI1zWdMeta?.name ?? "profile",
    path: indexWBV1cI1zWdMeta?.path ?? "/profile",
    meta: indexWBV1cI1zWdMeta || {},
    alias: indexWBV1cI1zWdMeta?.alias || [],
    redirect: indexWBV1cI1zWdMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexAJokLrfIYZMeta?.name ?? "profile-payment",
    path: indexAJokLrfIYZMeta?.path ?? "/profile/payment",
    meta: indexAJokLrfIYZMeta || {},
    alias: indexAJokLrfIYZMeta?.alias || [],
    redirect: indexAJokLrfIYZMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/payment/index.vue").then(m => m.default || m)
  },
  {
    name: settingsbsBr5Y9upcMeta?.name ?? "profile-settings",
    path: settingsbsBr5Y9upcMeta?.path ?? "/profile/settings",
    meta: settingsbsBr5Y9upcMeta || {},
    alias: settingsbsBr5Y9upcMeta?.alias || [],
    redirect: settingsbsBr5Y9upcMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: indexZkbTLpMpLHMeta?.name ?? "search",
    path: indexZkbTLpMpLHMeta?.path ?? "/search",
    meta: indexZkbTLpMpLHMeta || {},
    alias: indexZkbTLpMpLHMeta?.alias || [],
    redirect: indexZkbTLpMpLHMeta?.redirect,
    component: () => import("/home/lends-app-staging/htdocs/app.staging.lends.cloud/pages/search/index.vue").then(m => m.default || m)
  }
]